.popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.popup {
    background: white;
    padding: 2rem;
    border-radius: 8px;
    max-width: 500px;
    width: 90%;
    max-height: 80vh;
    /* Adjust height as needed */
    overflow-y: auto;
    z-index: 1001;
}

.popup-button-container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-top: 1rem;
    background-color: green;
    color: white;
    padding: 0.5rem 1rem;
    border-radius: 4px;
    cursor: pointer;
    width: 20%;
   
}
.nc-Footer {
    height: 20px;
   
}
@media (max-width: 768px) {
    .nc-Footer {
        padding-bottom: 0;
        margin-bottom: 0;
    }
}
@media (max-width: 768px) {
    .container {
        height: auto;
        padding-bottom: 0;
        
    }
}
.link {
    background: none;
    border: none;
    color: blue;
    cursor: pointer;
    text-decoration: underline;
    padding: 0;
    margin-right: 1rem;
}