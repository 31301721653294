/* Styles for dark mode, applied when the 'dark-datepicker' class is active */
.dark-datepicker .react-datepicker {
    background-color: #1f2937; /* Dark background */
    color: white; /* White text */
  }
  .dark-datepicker .react-datepicker__header {
    background-color: #1f2937; /* Dark gray background */
  }
  .dark-datepicker .react-datepicker__day-name,
  .dark-datepicker .react-datepicker__day,
  .dark-datepicker__time-name {
    color: white; /* White text for day names and day numbers */
  }
  .dark-datepicker .react-datepicker__day--selected,
  .dark-datepicker .react-datepicker__day--keyboard-selected {
    background-color: #374151; /* Slightly lighter gray for selected days */
    color: white;
  }
  .dark-datepicker .react-datepicker__day--today {
    background-color: #111827; /* Darker gray for today's date */
    color: white;
  }
  /* Hover effect: text turns black */
  .dark-datepicker .react-datepicker__day:hover,
  .dark-datepicker__time-name:hover {
    color: black; /* Black text on hover */
    background-color: #6b7280; /* Optional: slightly lighter background on hover */
  }
  /* Disabled dates show in gray */
  .dark-datepicker .react-datepicker__day--disabled {
    color: gray; /* Gray text for disabled dates */
    background-color: #1f2937; /* Keep background consistent with dark mode */
    pointer-events: none;
  }