.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}
.blur-effect {
  filter: blur(8px);
  pointer-events: none;
  user-select: none;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@media (max-width: 767px) {
  .mobile-visible {
    display: block !important;
  }
}

@media (min-width: 768px) {
  .mobile-hidden {
    display: none !important;
  }
}
.icon {
  display: inline-block;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .grid-cols-1 {
      grid-template-columns: repeat(1, minmax(0, 1fr));
  }
}

/* Dark Mode Styles */
.dark {
  color: #fff;
}
.AvatarDropdown:hover .tooltip {
  opacity: 1;
  visibility: visible;
 
}

.dark-bg {
  background-color: #343a40;
}

.dark-bg-lighter {
  background-color: #495057;
}
.bg-custom-green {
  background-color: #7BB542;
}

.bg-custom-green-dark {
  background-color: #114D33;
  /* Darker shade for hover effect */
}

 .input-focus-disable input[id^="react-select-"][type="text"]:focus {
  box-shadow: none !important;
  border: none !important;
  border-radius: .800rem !important;
  outline: none !important;
}

.input-focus-disable 
.css-13cymwt-control { 
  border-radius: .800rem !important;
}
.input-focus-disable 
.css-t3ipsp-control { 
  border-radius: .800rem !important;
}
.input-common-styles {
  border: 1px solid #ccc;
  padding: 0.5rem;
  border-radius: 0.25rem;
  width: 100%;
  box-sizing: border-box;
}
.alert {
  padding: 30px;
  border-radius: 4px;
  margin-bottom:30px;
}

.alert-red {
  background-color: #ffcccc;
  color: #cc0000;
}
.disabled-button {
  cursor: not-allowed;
 
  pointer-events: all;
}
.text-cream {
  color: #F5F5DC;
  
}
@keyframes slideInLeft {
  0% {
    transform: translateX(-100%);
  
    opacity: 0;
  }

  100% {
    transform: translateX(0);
    
    opacity: 1;
  }
}


.slide-in-left {
  animation: slideInLeft 1s ease-in-out forwards;
 
}
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: scale(0.95);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

.animate-fade-in {
  animation: fadeIn 1s ease-in-out;
}

/* Slide-in-left animation for the text */
@keyframes slideInLeft {
  from {
    opacity: 0;
    transform: translateX(-30px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

.slide-in-left {
  animation: slideInLeft 0.8s ease-out;
}